module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
  var lines = str.split('\n');
  var start = Math.max(lineno - 3, 0);
  var end = Math.min(lines.length, lineno + 3);
  var filename = esc(flnm);
  // Error context
  var context = lines.slice(start, end).map(function (line, i){
    var curr = i + start + 1;
    return (curr == lineno ? ' >> ' : '    ')
      + curr
      + '| '
      + line;
  }).join('\n');

  // Alter exception message
  err.path = filename;
  err.message = (filename || 'ejs') + ':'
    + lineno + '\n'
    + context + '\n\n'
    + err.message;

  throw err;
};
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
var __line = 1
  , __lines = "<li class=\"pv-h\">\n    <a href=\"javascript:void(0);\" class=\"filter js-filter\"><%= selected.title %></a>\n    <ul class=\"content\">\n        <%\n        for (var i = 0; i < data.length; i++) { %>\n            <li><a href=\"javascript:void(0);\" data-index=\"<%= i %>\" data-value=\"<%= data[i].value %>\"><%= data[i].title %></a></li>\n        <% } %>\n    </ul>\n</li>\n<select class=\"dropdown__filter__fake\">\n    <% for (var i = 0; i < data.length; i++) { %>\n        <option value=\"<%= data[i].value %>\"><%= data[i].title %></option>\n    <% } %>\n</select>"
  , __filename = "src/js/templates/filters/__filter.tpl";
try {
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  var __locals = (locals || {}),
data = __locals.data,
  selected = __locals.selected;
  with (locals || {}) {
    ; __append("<li class=\"pv-h\">\n    <a href=\"javascript:void(0);\" class=\"filter js-filter\">")
    ; __line = 2
    ; __append(escapeFn( selected.title ))
    ; __append("</a>\n    <ul class=\"content\">\n        ")
    ; __line = 4
    ; 
        for (var i = 0; i < data.length; i++) { 
    ; __line = 5
    ; __append("\n            <li><a href=\"javascript:void(0);\" data-index=\"")
    ; __line = 6
    ; __append(escapeFn( i ))
    ; __append("\" data-value=\"")
    ; __append(escapeFn( data[i].value ))
    ; __append("\">")
    ; __append(escapeFn( data[i].title ))
    ; __append("</a></li>\n        ")
    ; __line = 7
    ;  } 
    ; __append("\n    </ul>\n</li>\n<select class=\"dropdown__filter__fake\">\n    ")
    ; __line = 11
    ;  for (var i = 0; i < data.length; i++) { 
    ; __append("\n        <option value=\"")
    ; __line = 12
    ; __append(escapeFn( data[i].value ))
    ; __append("\">")
    ; __append(escapeFn( data[i].title ))
    ; __append("</option>\n    ")
    ; __line = 13
    ;  } 
    ; __append("\n</select>")
    ; __line = 14
  }
  return __output;
} catch (e) {
  rethrow(e, __lines, __filename, __line, escapeFn);
}

//# sourceURL="src/js/templates/filters/__filter.tpl"

}